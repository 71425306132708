/* ------------------------------------------------------------ *\
	Logo Underline
\* ------------------------------------------------------------ */

.logo-underline {
	display: inline-flex;
	align-items: flex-end;

	&__left,
	&__right {
		display: block;
	}

	&__left svg {
		width: rem(98px);
		height: rem(49px);
	}
	&__right svg {
		width: rem(121px);
		height: rem(49px);
	}

	&__middle {
		position: relative;
		display: block;
		padding: rem(10px) rem(15px);
		margin: 0 rem(5px);
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.logo-underline {
		&__left svg {
			width: 98px;
			height: 49px;
		}
		&__right svg {
			width: 121px;
			height: 49px;
		}

		&__middle {
			padding: 10px 15px;
			margin: 0 5px;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.logo-underline {
		&__left {
			width: 88.6px;
			height: auto;
		}

		&__middle {
			padding: 8px 12px;
		}

		&__right {
			width: 84.7px;
			height: auto;
		}
	}
}
