/* ------------------------------------------------------------ *\
	Intro
\* ------------------------------------------------------------ */

.intro {
	position: relative;
	padding: rem(105px) 0;

	&--no-bottom-padding {
		padding-bottom: 0 !important;
	}
	&__content {
		max-width: rem(800px);
	}

	&__subtitle {
		&:before {
			transition: width .5s .6s;
		}

		& > span {
			transition: opacity .5s .9s, transform .5s .9s;
		}
	}

	&__title {
		transition: opacity .5s 1.2s, transform .5s 1.2s;
		margin-bottom: rem(48px);
		font-size: rem(40px);
		font-weight: 800;
		line-height: 1.44;

		@include media-breakpoint-down(sm) {
				font-size: 36px;
		}
	}

	&__entry {
		max-width: rem(520px);
		margin-bottom: rem(15px);
		transition: opacity .5s 1.5s, transform .5s 1.5s;
	}

	&__actions {
		transition: opacity .5s 1.9s, transform .5s 1.9s;
	}

	&--narrow {
		.intro__content {
			max-width: rem(650px);
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.intro {
		padding: 60px 0;
	}
}
