/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */

.logo {
	display: inline-block;
	vertical-align: middle;
	text-decoration: none;
	font-size: 0;
	color: inherit;
}

.logo  {
	svg {
		height: 70px;
		width: auto;
	}
}

/* === XLarge === */
@include media-breakpoint-up(xxl) {
	.logo {
		svg {
			width: 145px;
			height: auto;
		}
	}
}
