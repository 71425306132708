html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

.c-scrollbar {
  width: 11px;
  height: 100vh;
  -webkit-transform-origin: 100%;
  transform-origin: 100%;
  opacity: 0;
  transition: -webkit-transform .3s, transform .3s, opacity .3s;
  position: absolute;
  top: 0;
  right: 0;
}

.c-scrollbar:hover {
  -webkit-transform: scaleX(1.45);
  transform: scaleX(1.45);
}

.c-scrollbar:hover, .has-scroll-dragging .c-scrollbar, .has-scroll-scrolling .c-scrollbar {
  opacity: 1;
}

.c-scrollbar_thumb {
  opacity: .5;
  width: 7px;
  cursor: -webkit-grab;
  cursor: grab;
  background-color: #000;
  border-radius: 10px;
  margin: 2px;
  position: absolute;
  top: 0;
  right: 0;
}

.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

* {
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  padding: 0;
}

:before, :after {
  box-sizing: inherit;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, main {
  display: block;
}

template {
  display: none;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

img, iframe, video, audio, object {
  max-width: 100%;
}

img, iframe {
  border: 0;
}

img {
  height: auto;
  vertical-align: middle;
  display: inline-block;
}

b, strong {
  font-weight: bold;
}

address {
  font-style: normal;
}

svg:not(:root) {
  overflow: hidden;
}

a, button, input[type="submit"], input[type="button"], input[type="reset"], input[type="file"], input[type="image"], label[for] {
  cursor: pointer;
}

a[href^="tel"], button[disabled], input[disabled], textarea[disabled], select[disabled] {
  cursor: default;
}

input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], textarea, a[href^="tel"] {
  -webkit-appearance: none;
  appearance: none;
}

textarea {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

button, select {
  text-transform: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

nav ul, nav ol {
  list-style: none;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block;
}

.p-relative {
  position: relative !important;
}

.p-fixed {
  position: fixed !important;
}

.w-100 {
  width: 100% !important;
}

.fade-in {
  opacity: 0;
  transition: opacity .5s .3s;
}

.fade-in.is-inview {
  opacity: 1;
}

.fade-in-up {
  opacity: 0;
  transition: opacity .5s .3s, -webkit-transform .5s .3s, transform .5s .3s;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

.fade-in-up.is-inview {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.fade-in-down {
  opacity: 0;
  transition: opacity .5s .3s, -webkit-transform .5s .3s, transform .5s .3s;
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.fade-in-down.is-inview {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.fade-in-up-large {
  opacity: 0;
  transition: opacity .5s .3s, -webkit-transform .6s .3s, transform .6s .3s;
  -webkit-transform: translateY(80px);
  transform: translateY(80px);
}

.fade-in-up-large.is-inview {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

html {
  color: #1a1919;
  background: #fff;
  font-family: Noto Sans, sans-serif;
  font-size: 1.1713vw;
  font-weight: 500;
  line-height: 1.4;
}

img {
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1rem;
}

h1, h2 {
  margin-bottom: 1.25rem;
  font-size: 3.125rem;
  font-weight: 800;
  line-height: 1.44;
}

h3 {
  font-size: 2.25rem;
  font-weight: 800;
  line-height: 1.1667;
}

h4 {
  font-size: 1.125rem;
  font-weight: 600;
}

h5 {
  text-transform: uppercase;
  margin-bottom: .625rem;
  font-size: 1rem;
  font-weight: 600;
}

h6 {
  font-size: .875rem;
}

p {
  margin-bottom: 1rem;
}

p:last-child {
  margin-bottom: 0;
}

.text-semi-large {
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 1.7;
}

.text-large {
  font-size: 1.5rem;
  line-height: 1.7;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

@media (max-width: 991.98px) {
  html {
    font-size: 1.757vw;
  }
}

@media (max-width: 767.98px) {
  html {
    font-size: 16px;
  }

  h1, h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 14px;
  }

  .text-large {
    font-size: 20px;
    line-height: 1.5;
  }
}

@font-face {
  font-family: Rollerscript;
  src: url("RollerscriptW03-Rough.976be5e8.woff2") format("woff2"), url("RollerscriptW03-Rough.2f0f90c9.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: NotoSans;
  src: url("NotoSans-Medium.f804df00.woff2") format("woff2"), url("NotoSans-Medium.d16ee001.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: NotoSans;
  src: url("NotoSans-SemiBold.cce7fa41.woff2") format("woff2"), url("NotoSans-SemiBold.de92d644.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

.fullsize-image {
  display: block;
  position: relative;
}

.fullsize-image img {
  width: 100%;
}

.subtitle {
  vertical-align: middle;
  text-transform: uppercase;
  vertical-align: middle;
  opacity: 0;
  margin-bottom: .625rem;
  padding-left: 5.625rem;
  font-size: 1rem;
  font-weight: 600;
  transition: opacity .5s .3s, -webkit-transform .5s .3s, transform .5s .3s;
  display: inline-block;
  position: relative;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

.subtitle:before {
  content: "";
  width: 0;
  height: 2px;
  will-change: width;
  background: currentColor;
  transition: width .5s;
  position: absolute;
  top: .6875rem;
  left: 0;
}

.subtitle--reversed {
  padding-left: 0;
  padding-right: 5.625rem;
}

.subtitle--reversed:before {
  left: auto;
  right: 0;
}

.subtitle--clean {
  padding-left: 0;
}

.subtitle--clean:before {
  display: none;
}

.subtitle--primary {
  color: #4cc2c5;
}

.subtitle--eon {
  color: #ff0201;
}

.subtitle--ehr {
  color: #289f89;
}

.subtitle--legitsign {
  color: #00bd94;
}

.subtitle--ambire {
  color: #aa6aff;
}

.subtitle--bgmamma {
  color: #27dfba;
}

.subtitle--body {
  color: #1a1919 !important;
}

.subtitle.is-inview {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.subtitle.is-inview:before {
  width: 3.75rem;
}

@media (max-width: 767.98px) {
  .subtitle {
    padding-left: 50px;
  }

  .subtitle--reversed {
    padding-left: 0;
    padding-right: 50px;
  }

  .subtitle.is-inview:before {
    width: 40px;
  }
}

.logo {
  vertical-align: middle;
  color: inherit;
  font-size: 0;
  text-decoration: none;
  display: inline-block;
}

.logo svg {
  height: 70px;
  width: auto;
}

@media (min-width: 1700px) {
  .logo svg {
    width: 145px;
    height: auto;
  }
}

.logo-animated {
  align-items: flex-end;
  display: inline-flex;
}

.logo-animated__left, .logo-animated__right {
  display: block;
}

.logo-animated svg {
  max-width: 100%;
  max-height: 100%;
}

.logo-animated__middle {
  width: 0;
  height: 3.125rem;
  will-change: width;
  border-bottom: 6px solid;
  margin-right: 2px;
  transition: width .7s ease-in-out;
  display: block;
  position: relative;
  overflow: hidden;
}

.logo-animated__middle__text {
  white-space: nowrap;
  opacity: 0;
  padding: 0 1.25rem;
  font-family: Rollerscript, cursive;
  font-size: 2rem;
  font-weight: 400;
  transition: opacity .4s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.logo-animated__middle__text.active {
  opacity: 1;
  transition: opacity .4s .3s;
  position: static;
}

@media (min-width: 1700px) {
  .logo-animated {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }
}

@media (max-width: 767.98px) {
  .logo-animated__left {
    width: 68.6px;
  }

  .logo-animated__right {
    width: 84.7px;
  }

  .logo-animated__middle__text {
    padding: 0 15px;
    font-size: 24px;
  }
}

.logo-underline {
  align-items: flex-end;
  display: inline-flex;
}

.logo-underline__left, .logo-underline__right {
  display: block;
}

.logo-underline__left svg {
  width: 6.125rem;
  height: 3.0625rem;
}

.logo-underline__right svg {
  width: 7.5625rem;
  height: 3.0625rem;
}

.logo-underline__middle {
  margin: 0 .3125rem;
  padding: .625rem .9375rem;
  display: block;
  position: relative;
}

@media (max-width: 1279.98px) {
  .logo-underline__left svg {
    width: 98px;
    height: 49px;
  }

  .logo-underline__right svg {
    width: 121px;
    height: 49px;
  }

  .logo-underline__middle {
    margin: 0 5px;
    padding: 10px 15px;
  }
}

@media (max-width: 767.98px) {
  .logo-underline__left {
    width: 88.6px;
    height: auto;
  }

  .logo-underline__middle {
    padding: 8px 12px;
  }

  .logo-underline__right {
    width: 84.7px;
    height: auto;
  }
}

.socials {
  color: inherit;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  font-size: .875rem;
  display: flex;
}

.socials .link {
  color: inherit;
  text-decoration: none;
  transition: color .3s;
  display: block;
  position: relative;
}

.socials .link:hover {
  color: #4cc2c5;
}

.socials .link:before {
  content: "";
  width: 4px;
  height: 2px;
  background: currentColor;
  position: absolute;
  top: .625rem;
  left: -5px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.socials .link:first-child:before {
  display: none;
}

@media (max-width: 991.98px) {
  .socials {
    font-size: 14px;
  }

  .socials.socials__header .link:before {
    display: none;
  }

  .socials .link:before {
    top: 10px;
  }
}

.link-more {
  vertical-align: middle;
  color: inherit;
  background: none;
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 800;
  text-decoration: none;
  transition: text-shadow .3s;
  display: inline-flex;
}

.link-more svg {
  margin-top: .125rem;
  margin-left: .9375rem;
  display: inline-block;
  width: 1.25rem !important;
  height: .75rem !important;
}

.no-touchevents .link-more:hover svg {
  -webkit-animation: moveArrow 1s infinite;
  animation: moveArrow 1s infinite;
}

.no-touchevents .link-more--down:hover svg {
  -webkit-animation: moveArrowDown 1s infinite;
  animation: moveArrowDown 1s infinite;
}

@media (min-width: 1700px) {
  .link-more {
    font-size: 24px;
  }
}

@-webkit-keyframes moveArrow {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  30% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes moveArrow {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  30% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes moveArrowDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  30% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes moveArrowDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  30% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.weather-inline {
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.weather-inline__icon {
  vertical-align: middle;
  width: 2.125rem;
  height: 2.125rem;
  margin-right: .25rem;
  display: inline-block;
}

.paging {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.paging__next, .paging__prev {
  vertical-align: middle;
  background: none;
  border: 0;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  padding: .875rem .4375rem;
  display: inline-flex;
}

.paging__next:hover, .paging__prev:hover {
  color: #4cc2c5;
}

.paging__next svg, .paging__prev svg {
  width: 1.875rem !important;
  height: 1.125rem !important;
}

.menu-is-opened .header.scrolled {
  background: none;
  transition: background 0s 1.2s;
}

.menu-is-opened .header:before {
  opacity: 0;
  transition: -webkit-transform .105s, transform .105s, opacity 0s 1.2s;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

.menu-is-closing .header .logo, .menu-is-closing .header .nav-trigger {
  transition: color .3s .7s;
}

.menu-is-opened .header .nav-trigger, .menu-is-opened .header .logo {
  color: #fff;
  transition: color .3s;
}

.header--light .nav-trigger {
  color: #fff;
}

.header--light .logo {
  color: #4cc2c5;
}

.header.scrolled {
  background: inherit;
  color: inherit;
}

.header.scrolling-down {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

@media (max-width: 767.98px) {
  .header__inner {
    padding: 20px 0;
  }
}

.nav {
  width: 100%;
  height: 100%;
  color: inherit;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  font-weight: 800;
  display: flex;
}

.nav__link:not(:hover) {
  opacity: .3;
}

.no-touchevents .nav__link-wrapper:hover + .nav__image {
  visibility: visible;
  opacity: 1;
}

.nav__link {
  text-transform: capitalize;
  vertical-align: middle;
  color: inherit;
  text-decoration: none;
  transition: opacity .6s;
  display: inline-block;
}

.nav__image {
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity .6s, visibility .6s;
  position: absolute;
  top: 0;
  left: 0;
}

.nav__image:after {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(26, 25, 25, .8);
  position: absolute;
  top: 0;
  left: 0;
}

.nav__item {
  padding: 0 2.5rem;
}

@media (max-width: 991.98px) {
  .nav {
    text-align: center;
    flex-direction: column;
  }

  .nav__item {
    padding: 1.875rem 0;
  }
}

@media (max-width: 767.98px) {
  .nav {
    padding-top: 40px;
    font-size: 36px;
  }

  .nav__link:not(:hover) {
    opacity: 1;
  }

  .nav__item {
    padding: .625rem 0;
  }
}

.nav-trigger {
  vertical-align: middle;
  width: 30px;
  height: 28px;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  padding: 0;
  font-size: 0;
  line-height: 1;
  display: inline-block;
  position: relative;
}

.nav-trigger span {
  width: 100%;
  height: 4px;
  pointer-events: none;
  background: currentColor;
  display: block;
  position: absolute;
  left: 0;
}

.nav-trigger span:nth-child(1), .nav-trigger span:nth-child(3) {
  transition: top .2s .2s, -webkit-transform .2s, transform .2s;
}

.nav-trigger span:nth-child(1) {
  top: 0;
}

.nav-trigger span:nth-child(2) {
  transition: opacity 0s .2s;
  top: 12px;
}

.nav-trigger span:nth-child(3) {
  top: 24px;
}

.menu-is-opened .nav-trigger span:nth-child(1), .menu-is-opened .nav-trigger span:nth-child(3) {
  transition: top .2s, -webkit-transform .2s .2s, transform .2s .2s;
  top: 12px;
}

.menu-is-opened .nav-trigger span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menu-is-opened .nav-trigger span:nth-child(2) {
  opacity: 0;
}

.menu-is-opened .nav-trigger span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media (min-width: 1700px) {
  .nav-trigger {
    width: 45px;
    height: 42px;
  }

  .nav-trigger span {
    height: 6px;
  }

  .nav-trigger span:nth-child(2) {
    top: 18px;
  }

  .nav-trigger span:nth-child(3) {
    top: 36px;
  }

  .menu-is-opened .nav-trigger span:nth-child(1), .menu-is-opened .nav-trigger span:nth-child(3) {
    top: 18px;
  }
}

.footer {
  color: #1a1919;
  padding-top: 6.25rem;
}

.footer__logo {
  text-align: center;
  padding: 1.5625rem 0 7.5rem;
}

.footer__cols {
  flex-wrap: wrap;
  justify-content: center;
  gap: .9375rem 3.75rem;
  display: flex;
}

.footer__col {
  min-width: 15rem;
}

.footer__title {
  margin-bottom: .625rem;
}

.footer__entry, .footer__entry--small {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6;
}

.footer__entry a, .footer__entry--small a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  transition: color .3s;
}

.footer__entry a:hover, .footer__entry--small a:hover {
  color: #4cc2c5;
}

.footer__entry--small {
  font-size: .875rem;
}

.footer__bar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 3.125rem 0;
  display: flex;
}

.footer__copyright {
  font-size: .875rem;
}

@media (max-width: 991.98px) {
  .footer__copyright {
    font-size: 14px;
  }
}

@media (max-width: 767.98px) {
  .footer {
    text-align: center;
    padding-top: 60px;
  }

  .footer__logo {
    padding: 40px 0 60px;
  }

  .footer__cols {
    text-align: center;
    justify-content: center;
  }

  .footer__col {
    padding: 0;
  }

  .footer__entry {
    font-size: 20px;
  }

  .footer__bar {
    justify-content: center;
    padding: 30px 0;
  }

  .footer__copyright {
    width: 100%;
    order: 2;
    margin-top: 20px;
  }
}

.container {
  width: 100%;
  max-width: 72.25rem;
  margin: 0 auto;
  padding: 0 3.75rem;
}

.container--large {
  max-width: 80.625rem;
}

.container--tiny {
  max-width: 51.25rem;
}

@media (max-width: 767.98px) {
  .container {
    padding: 0 20px;
  }
}

.menu {
  z-index: 100;
  width: 100%;
  height: 100%;
  color: #fff;
  background: #1a1919;
  transition: -webkit-transform .4s ease-in-out .6s, transform .4s ease-in-out .6s;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.menu .menu-background {
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.menu .menu-background[data-page="home"] {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(rgba(26, 25, 25, .7), rgba(26, 25, 25, .7)), url("home.7430efac.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.menu .menu-background[data-page="approach"] {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(rgba(26, 25, 25, .7), rgba(26, 25, 25, .7)), url("approach.b3ada0bb.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.menu .menu-background[data-page="culture"] {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(rgba(26, 25, 25, .7), rgba(26, 25, 25, .7)), url("culture.34e70065.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.menu .menu-background[data-page="tribe"] {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(rgba(26, 25, 25, .7), rgba(26, 25, 25, .7)), url("tribe.eda97f41.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.menu .menu-background[data-page="web3"] {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(rgba(26, 25, 25, .7), rgba(26, 25, 25, .7)), url("web3.a9ea16d1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.menu .menu-background.background-hidden {
  opacity: 0;
}

.menu .nav .nav__link-wrapper {
  opacity: 0;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
}

.menu .nav .nav__item:nth-last-child(1) .nav__link-wrapper {
  transition: opacity .4s .125s, -webkit-transform .4s .125s, transform .4s .125s;
}

.menu .nav .nav__item:nth-last-child(2) .nav__link-wrapper {
  transition: opacity .4s .25s, -webkit-transform .4s .25s, transform .4s .25s;
}

.menu .nav .nav__item:nth-last-child(3) .nav__link-wrapper {
  transition: opacity .4s .375s, -webkit-transform .4s .375s, transform .4s .375s;
}

.menu .nav .nav__item:nth-last-child(4) .nav__link-wrapper {
  transition: opacity .4s .5s, -webkit-transform .4s .5s, transform .4s .5s;
}

.menu-is-opened .menu {
  transition: -webkit-transform .4s ease-in-out, transform .4s ease-in-out;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

.menu-is-opened .menu .nav .nav__item .nav__link-wrapper {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.menu-is-opened .menu .nav .nav__item:nth-child(1) .nav__inner {
  transition: opacity .4s .525s, -webkit-transform .4s .525s, transform .4s .525s;
}

.menu-is-opened .menu .nav .nav__item:nth-child(2) .nav__inner {
  transition: opacity .4s .65s, -webkit-transform .4s .65s, transform .4s .65s;
}

.menu-is-opened .menu .nav .nav__item:nth-child(3) .nav__inner {
  transition: opacity .4s .775s, -webkit-transform .4s .775s, transform .4s .775s;
}

.menu-is-opened .menu .nav .nav__item:nth-child(4) .nav__inner {
  transition: opacity .4s .9s, -webkit-transform .4s .9s, transform .4s .9s;
}

.menu-is-opened .menu:after {
  width: 0;
  height: 0;
  z-index: -1;
  content: url("home.7430efac.jpg") url("approach.b3ada0bb.jpg") url("culture.34e70065.jpg") url("tribe.eda97f41.jpg");
  position: absolute;
  overflow: hidden;
}

.menu__container {
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  flex-direction: column;
  padding: 1.875rem 0;
  transition: opacity .3s .4s, visibility .3s .4s;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}

.menu-is-opened .menu__container {
  opacity: 1;
  visibility: visible;
  transition: opacity .3s .4s, visibility .3s .4s;
}

.menu__top {
  padding-bottom: 4.375rem;
}

.menu__middle {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.menu__bottom {
  border-top: 1px solid rgba(255, 255, 255, .3);
  justify-content: space-between;
  padding: 3.75rem 0 1.875rem;
  display: flex;
  position: relative;
}

.menu__message {
  color: rgba(255, 255, 255, .7);
  font-size: 1.25rem;
}

.menu__message strong {
  font-weight: 600;
}

.menu__message a {
  color: #fff;
  text-decoration: none;
  transition: color .3s;
}

.menu__message a:hover {
  color: #4cc2c5;
}

@media (max-width: 991.98px) {
  .menu {
    padding: 0;
  }

  .menu__top {
    padding-bottom: 0;
  }

  .menu-is-opened .menu:after {
    width: 0;
    height: 0;
    z-index: -1;
    content: url("home-tablet.87c689a5.jpg") url("approach-tablet.82d4a434.jpg") url("culture-tablet.e900860f.jpg") url("tribe-tablet.5161fda3.jpg");
    position: absolute;
    overflow: hidden;
  }

  .menu .menu-background {
    transition: opacity .3s linear;
  }

  .menu .menu-background[data-page=""] {
    opacity: 0;
  }

  .menu .menu-background[data-page="home"] {
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(rgba(26, 25, 25, .7), rgba(26, 25, 25, .7)), url("home-tablet.87c689a5.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
  }

  .menu .menu-background[data-page="approach"] {
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(rgba(26, 25, 25, .7), rgba(26, 25, 25, .7)), url("approach-tablet.82d4a434.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
  }

  .menu .menu-background[data-page="culture"] {
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(rgba(26, 25, 25, .7), rgba(26, 25, 25, .7)), url("culture-tablet.e900860f.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
  }

  .menu .menu-background[data-page="tribe"] {
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(rgba(26, 25, 25, .7), rgba(26, 25, 25, .7)), url("tribe-tablet.5161fda3.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
  }
}

@media (max-width: 767.98px) {
  .menu {
    background: #1a1919;
  }

  .menu-is-opened .menu:after {
    width: 0;
    height: 0;
    z-index: -1;
    content: url("home-mobile.f0c19e7f.jpg") url("approach-mobile.ae61542c.jpg") url("culture-mobile.0c29ac99.jpg") url("tribe-mobile.47f8b941.jpg");
    position: absolute;
    overflow: hidden;
  }

  .menu.home-page {
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(rgba(26, 25, 25, .7), rgba(26, 25, 25, .7)), url("home-mobile.f0c19e7f.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
  }

  .menu.approach-page {
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(rgba(26, 25, 25, .7), rgba(26, 25, 25, .7)), url("approach-mobile.ae61542c.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
  }

  .menu.culture-page {
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(rgba(26, 25, 25, .7), rgba(26, 25, 25, .7)), url("culture-mobile.0c29ac99.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
  }

  .menu.tribe-page {
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(rgba(26, 25, 25, .7), rgba(26, 25, 25, .7)), url("tribe-mobile.47f8b941.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
  }

  .menu__container {
    padding: 20px 0;
  }

  .menu__bottom {
    text-align: center;
    padding: 30px 0 10px;
    display: block;
  }

  .menu__message {
    margin-bottom: 20px;
    font-size: 18px;
  }

  .menu .socials {
    display: inline-block;
  }
}

.intro {
  padding: 6.5625rem 0;
  position: relative;
}

.intro--no-bottom-padding {
  padding-bottom: 0 !important;
}

.intro__content {
  max-width: 50rem;
}

.intro__subtitle:before {
  transition: width .5s .6s;
}

.intro__subtitle > span {
  transition: opacity .5s .9s, -webkit-transform .5s .9s, transform .5s .9s;
}

.intro__title {
  margin-bottom: 3rem;
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 1.44;
  transition: opacity .5s 1.2s, -webkit-transform .5s 1.2s, transform .5s 1.2s;
}

@media (max-width: 767.98px) {
  .intro__title {
    font-size: 36px;
  }
}

.intro__entry {
  max-width: 32.5rem;
  margin-bottom: .9375rem;
  transition: opacity .5s 1.5s, -webkit-transform .5s 1.5s, transform .5s 1.5s;
}

.intro__actions {
  transition: opacity .5s 1.9s, -webkit-transform .5s 1.9s, transform .5s 1.9s;
}

.intro--narrow .intro__content {
  max-width: 40.625rem;
}

@media (max-width: 767.98px) {
  .intro {
    padding: 60px 0;
  }
}

.media-section {
  overflow: hidden;
}

.media-section__wrapper {
  z-index: 2;
  position: relative;
}

.media-section__inner {
  width: calc(50% + 50vw);
  background: inherit;
  text-align: center;
  margin-right: calc(50% - 50vw);
  position: relative;
  overflow: hidden;
}

.media-section__inner--shadow {
  border-radius: 5px 0 0 5px;
  box-shadow: -1px 0 1px -1px #fbfbff;
}

.media-section__inner--top {
  z-index: 199;
}

.media-section__media {
  justify-content: center;
  align-items: center;
  padding-top: 56.25%;
  display: flex;
  position: relative;
}

.media-section__video, .media-section__image {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.media-section--reversed .media-section__inner {
  margin-right: unset;
  margin-left: calc(50% - 50vw);
}

.media-section--padding {
  padding: 4.375rem 0;
}

@media (max-width: 767.98px) {
  .media-section__image {
    width: 100%;
    margin: 0;
  }
}

.section {
  margin: 11.25rem 0 6.25rem;
}

.section__head {
  max-width: 57.5rem;
  text-align: center;
  margin: 0 auto 3.75rem;
}

@media (max-width: 991.98px) {
  .section {
    margin-top: 7.5rem;
  }
}

@media (max-width: 767.98px) {
  .section {
    margin: 60px 0;
  }
}

.section-slider {
  padding: 6.5625rem 0;
  position: relative;
  overflow: hidden;
}

.section-slider__head {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5.9375rem;
  display: flex;
  position: relative;
}

.section-slider__title {
  width: 50%;
  max-width: 25rem;
  margin-bottom: 0;
}

.section-slider__entry {
  width: 50%;
  padding-right: 2.5rem;
}

@media (max-width: 991.98px) {
  .section-slider__title {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1.25rem;
  }

  .section-slider__entry {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .section-slider {
    padding: 60px 0;
  }

  .section-slider__head {
    padding-bottom: 60px;
  }
}

.section-list {
  margin: 6.5625rem 0;
}

.section-list__subtitle {
  color: #4cc2c5;
  margin-bottom: 0;
  font-weight: 800;
}

.section-list__item {
  border-bottom: 1px solid rgba(26, 25, 25, .1);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1.875rem 0;
  display: flex;
}

.section-list__item__head {
  width: 50%;
  padding-right: .9375rem;
}

.section-list__item__title {
  margin-bottom: 0;
  font-size: 1.5rem;
}

.section-list__item__body {
  width: 50%;
  padding-left: .9375rem;
}

.section-list__item__entry {
  max-width: 28.125rem;
}

.section-list__item__image {
  opacity: 0;
  display: none;
  position: absolute;
}

.section-list__item--triple .section-list__item__head {
  width: 30%;
}

.section-list__item--triple .section-list__item__media {
  width: 20%;
}

.section-list__item__media__item:not(:last-child) {
  margin-right: 10px;
}

@media (max-width: 767.98px) {
  .section-list {
    margin: 60px 0;
  }

  .section-list__item__head {
    width: 100%;
    padding-right: 0;
  }

  .section-list__item__title {
    margin-bottom: 10px;
  }

  .section-list__item__body {
    width: 100%;
    padding-left: 0;
  }

  .section-list__item--triple .section-list__item__media, .section-list__item--triple .section-list__item__head {
    width: 100%;
  }

  .section-list__item--triple .section-list__item__media {
    margin-bottom: 10px;
  }
}

.slider {
  counter-reset: projects-counter;
}

.slider .dragged .thumb {
  -webkit-transform: scale(.95);
  transform: scale(.95);
}

.slider__clip {
  margin: 0 -.9375rem;
  overflow: visible;
}

.slider__slide {
  padding: 0 .9375rem;
}

.slider__paging {
  display: none;
}

.no-touchevents .slider:hover .thumb {
  opacity: .7;
}

.no-touchevents .slider .thumb:hover {
  opacity: 1;
}

@media (max-width: 991.98px) {
  .slider__paging {
    margin-bottom: 10px;
    padding: 0 20px;
    display: block;
  }
}

@media (max-width: 767.98px) {
  .slider__clip {
    margin: 0 -5px;
  }

  .slider__slide {
    padding: 0 5px;
  }
}

.text-image {
  padding: 6.5625rem 0;
}

.text-image__inner {
  flex-wrap: wrap;
  display: flex;
}

.text-image__content {
  width: 50%;
  padding: 2rem 1.25rem 2rem 0;
}

.text-image__entry {
  margin-bottom: 1.875rem;
}

.text-image__media {
  width: 50%;
}

.text-image__image img {
  border-radius: 5px;
}

.text-image__image__decoration {
  z-index: -1;
  background: rgba(169, 200, 198, .5);
  border-radius: 5px;
  position: absolute;
  top: 3.125rem;
  bottom: -2.5rem;
  left: 2rem;
  right: -3.75rem;
}

.text-image__image__decoration--dark {
  background: rgba(59, 59, 61, .5);
}

@media (max-width: 991.98px) {
  .text-image__content {
    width: 100%;
    margin-bottom: 3.75rem;
    padding: 0;
  }

  .text-image__media {
    width: 100%;
    padding-right: 3.75rem;
  }
}

@media (max-width: 767.98px) {
  .text-image {
    padding: 60px 0;
  }
}

/*# sourceMappingURL=404.f57baa63.css.map */
