/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
	padding-top: rem(100px);
	color: $body-color;

	&__logo {
		padding: rem(25px) 0 rem(120px);
		text-align: center;
	}

	&__cols {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: rem(15px) rem(60px);
	}

	&__col {
		min-width: rem(240px);
	}

	&__title {
		margin-bottom: rem(10px);
	}

	&__entry,
	&__entry--small {
		font-size: rem(20px);
		font-weight: 400;
		line-height: 1.6;

		a {
			color: inherit;
			text-decoration: none;
			cursor: pointer;
			transition: color 0.3s;

			&:hover {
				color: $primary;
			}
		}
	}

	&__entry--small {
		font-size: rem(14px);
	}

	&__bar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		padding: rem(50px) 0;
	}

	&__copyright {
		font-size: rem(14px);
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.footer {
		&__copyright {
			font-size: 14px;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.footer {
		padding-top: 60px;
		text-align: center;

		&__logo {
			padding: 40px 0 60px;
		}

		&__cols {
			justify-content: center;
			text-align: center;
		}

		&__col {
			padding: 0;
		}

		&__entry {
			font-size: 20px;

			// a {
			// 	color: $primary;
			// }
		}

		&__bar {
			justify-content: center;
			padding: 30px 0;
		}

		&__copyright {
			order: 2;
			width: 100%;
			margin-top: 20px;
		}
	}
}
